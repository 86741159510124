document.documentElement.className = document.documentElement.className.replace(/\bno-js\b/g, "") + "js"

if (window.location.hash) { setTimeout(function () { window.scrollTo(0, 0); }, 2); }

jQuery(document).ready(function ($) {

    AOS.init({
        delay: 250,
        once: true,
        disable: 'mobile'
    });

    /*-----------------------------------------------------------------------------GLOBAL ON LOAD----*/
   var LazyLoading = (function () {
        var instance = new LazyLoad();

        function lazyBGImages() {
            var $bgImages = $('[data-bg]:not(.lazy)');
            if ($bgImages.length) {
                $bgImages.each(function () {
                    $(this).addClass('lazy');
                });
            }
            instance.update();
        }

        function update() {
            lazyBGImages();
        }

        lazyBGImages();

        return {
            update: update
        }
    }());
    var SmoothScroll = (function () {
        var $anchorLinks = $('a[href^="#"]').not('a[href="#"]');

        $('a[href="#"]').click(
            function (e) { e.preventDefault(); return; }
        );

        function goTo(target) {
            if (target === "" || !$(target).length) { return; }
            var scrollPos = typeof target === 'number' ? target : $(target).offset().top;

            if (window.innerWidth >= 720) {
                scrollPos -= $('header').outerHeight();
            } else {
                scrollPos -= $('header').outerHeight() * 2;
            }

            $('html, body').stop().animate({
                'scrollTop': scrollPos - 32
            }, 1200, 'swing', function () {
                if (typeof target === 'string') {

                    if (window.location.hash) {
                        // window.location.hash = target;
                    }
                }
            });
        }

        if (window.location.hash) {
            setTimeout(function () {
                goTo(window.location.hash);
            }, 500);
        }

        if ($anchorLinks.length) {
            $anchorLinks.on('click', function (e) {
                if (!$("#" + this.hash.replace('#', '')).length) { return; }
                e.preventDefault();
                goTo(this.hash);
            });
        }

        return { to: goTo }
    }());

    var footerMenus = (function() {
        $('.menu--foot').on('click', '.mobile-arrow', function() {
            $('.footer-main__nav .dropdown').removeClass("active");
            if ($(this).hasClass('active')) {
                $(this).removeClass("active");
                $(this).closest('.dropdown').find('.dropdown').removeClass("active");
            } else {
                $('footer').find('.mobile-arrow').removeClass("active");
                $(this).addClass("active");
                $(this).closest('.dropdown').find('.dropdown').addClass("active");
            }
        });

    }());

    //Global function to toggle simple accordions
    var Accordions = (function () {
        var $accordions = $('.accordion');
        if (!$accordions.length) { return; }

        $accordions.each(function () {
            if ($(this).hasClass('active')) {
                $(this).find('.accordion__content').show();
            }
        });
        var $ogIMG = $('.image-accordion-row').find('.image-accordion-row__column--img').html();

        $accordions.find('.accordion__trigger').click(function (e) {
            var $this = $(this);
            var $accordion = $this.parent();
            var $content = $accordion.find('.accordion__content');
            var $siblings = $accordion.parent().siblings();

            var $img = $this.closest('.accordion').find('.accordion-img').html();


            if ($accordion.hasClass('active')) {
                $accordion.removeClass('active');
                $content.slideUp('fast');

                console.log($ogIMG);

                if($img) {
                    $this.closest('.image-accordion-row').find('.image-accordion-row__column--img').html($ogIMG);
                }


            } else {
                $accordion.addClass('active');
                $siblings.find('.accordion__item').removeClass('active').find('.accordion__content').slideUp('fast');
                $content.slideDown('fast');

                if($img) {
                    $this.closest('.image-accordion-row').find('.image-accordion-row__column--img img').attr('src', $img);
                }

            }
        })

    }());

    var Forms = (function () {
        var InputMasks = (function () {
            var $masks = $('[data-mask]');
            if (!$masks.length) { return; }

            /**
             * Key Codes:
             * 8    - backspace
             * 13   - enter
             * 16   - shift
             * 18   - alt
             * 20   - caps
             * 27   - esc
             * 37   - left arrow
             * 38   - up arrow
             * 39   - right arrow
             * 40   - down arrow
             * 46   - delete
             **/
            var exclude_keys = [8, 13, 16, 18, 20, 27, 37, 38, 39, 40, 46];

            $('[data-mask]').keyup(function (e) {
                console.log(e.keyCode);
                if (exclude_keys.indexOf(e.keyCode) > -1) { return; }

                switch (this.dataset.mask) {
                    case 'digits':
                        var x = this.value.replace(/\D/g, '');
                        this.value = x;
                        break;
                    case 'phone':
                        var x = this.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                        console.log(x);
                        this.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
                        break;
                    case 'ssn': {
                        var x = this.value.replace(/\D/g, '').match(/^(\d{0,3})(\d{0,2})(\d{0,4})/);
                        this.value = !x[2] ? x[1] : x[1] + '-' + x[2] + '-' + x[3];
                    }
                }
            });
        }());

        //Plugin used for form validation
        var parselyOptions = {
            classHandler: function (parsleyField) {
                var $element = parsleyField.$element;
                if ($element.parent().hasClass('select-menu')) {
                    return $element.parent();
                }
                return $element;
            },
            errorsContainer: function (parsleyField) {
                var $element = parsleyField.$element;
                var $fieldContainer = $element.closest('.form-field');
                if ($fieldContainer) {
                    return $fieldContainer;
                }
            }
        };

        //Global function to set form state classes
        var formStates = (function () {
            $formInputs = $('form :input');
            if (!$formInputs.length) { return; }

            $formSelectMenus = $('.select-menu select, .ginput_container_select select');

            function isGFormInput($el) {
                return $el.parent().hasClass('ginput_container') ? $el.parent().parent() : $el;
            }

            function setFilled($input) {
                $input.addClass('filled');
            }

            function removeFilled($input) {
                $input.removeClass('filled');
            }

            function setFocused() {
                $(this).addClass('focused');
            }

            function removeFocused() {
                $(this).removeClass('focused');
            }

            function checkInput(e) {
                if (this.type == 'button' ||
                    this.type == 'range' ||
                    this.type == 'submit' ||
                    this.type == 'radio' ||
                    this.type == 'checkbox' ||
                    this.type == 'reset') { return; }

                var $this = $(this);
                var $parent = $this.parent();
                var is_selectMenu = $parent.hasClass('select-menu') || $parent.hasClass('ginput_container_select');

                var $input = is_selectMenu ? $parent : $this;

                switch (this.type) {
                    case 'select-one':
                    case 'select-multiple':
                        if (this.value !== '') {
                            setFilled($input);
                        } else {
                            removeFilled($input);
                        }
                        break;
                    default:
                        if (this.value !== '') {
                            setFilled($input);
                        } else {
                            removeFilled($input);
                        }
                        break;
                }
            }

            $formInputs.each(checkInput);
            $formInputs.on('change', checkInput);
            $formInputs.on('focus', setFocused);
            $formInputs.on('blur', removeFocused);
            $formSelectMenus.on('focus', setFocused);
            $formSelectMenus.on('blur', removeFocused);

        }());
    }());

    //Global function top open / close lightboxes
    var PDMLightbox = (function () {

        var $body = $('body');
        //Lightbox reset - This lightbox is empty and present on all pages
        var $lightbox = $('.pdm-lightbox--reset');

        //it's content can be filled from various sources
        //after close, the content is wiped
        var $lightbox_content = $('.pdm-lightbox--reset .pdm-lightbox__content');

        $body.on('click', '[data-lightbox-iframe],[data-lightbox-content],[data-lightbox-target]', function (e) {

            e.preventDefault();

            var classes = $(this).data('lightbox-classes');
            var iframe = $(this).data('lightbox-iframe');
            var blur = $(this).data('lightbox-blur');

            if (iframe) {

                var youtubePattern = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g;
                var vimeoPattern = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;

                if (youtubePattern.test(iframe)) {

                    classes += ' youtube-vid'

                    replacement = '<div class="spacer"><iframe width="560" height="315" frameborder="0" allowfullscreen src="//www.youtube.com/embed/$1?autoplay=1&modestbranding=1&rel=0&iv_load_policy=3" /></div>';

                    iframe = iframe.replace(youtubePattern, replacement);

                }

                if (vimeoPattern.test(iframe)) {

                    classes += ' vimeo-vid'

                    replacement = '<div class="spacer"><iframe width="560" height="315" frameborder="0" allowfullscreen src="//player.vimeo.com/video/$1?autoplay=1&modestbranding=1&rel=0&iv_load_policy=3" /></div>';

                    iframe = iframe.replace(vimeoPattern, replacement);

                }

                $lightbox_content.html('<div class="iframe-wrap">' + iframe + '</div>');

            } else {
                if ($(this).data('lightbox-content')) {
                    var content = $(this).data('lightbox-content');
                } else if ($(this).data('lightbox-target')) {
                    var target = $(this).data('lightbox-target');
                    var content = $('#' + target).html();
                }
                $lightbox_content.html(content);
            }

            if (blur != false) {
                $body.addClass('blur');
            }

            $lightbox.addClass('active').addClass(classes);

        });

        function closeLightbox($lightbox) {
            $lightbox.removeClass('active');
            $('body').removeClass('no-scroll');
            setTimeout(function () {
                $body.removeClass('blur');
            }, 250);
            //wait before removing classes till lightbox closses
            if ($lightbox.hasClass('pdm-lightbox--reset')) {
                setTimeout(function () {
                    $lightbox.find('.pdm-lightbox__content').empty();
                    $lightbox.attr('class', 'pdm-lightbox pdm-lightbox--reset');
                }, 750);
            }
        }

        $('.pdm-lightbox').on('click', function (e) {
            var $lightbox = $(this);
            if (e.target == this) {
                closeLightbox($lightbox);
            }
        });

        $('.pdm-lightbox__close').click(function (e) {
            e.stopPropagation();
            $lightbox = $(this).closest('.pdm-lightbox');
            closeLightbox($lightbox);
        });
        return {
            close: closeLightbox
        };
    }());


  $('.dropdown-btn').click(function() {
        $(this).parent().find(".dropdown-container").toggleClass("active");
    });



    //******************************************************Everything under this is optional, feel free to delete

       var Header = (function() {
        var $body = $('body');
        var $header = $('header.gheader');
        var $nav = $header.find('nav.global');
        var $adminBar = $('#wpadminbar');

        var headerHeight = $header.innerHeight();
        if ($adminBar.length) { headerHeight += $adminBar.innerHeight(); }
         if (window.innerWidth < 960) {
            $nav.css({ marginTop: headerHeight });
        }

        window.addEventListener('resize', function() {
            if (window.innerWidth < 960) {
                if ($adminBar.length) {
                    $nav.css({ marginTop: headerHeight })
                }
            } else {
                $nav.css({ marginTop: 0 });
            }
        });

        var BurgerMenu = (function() {
            var $burgerMenu = $header.find('.menu-burger');
            var $text = $burgerMenu.find('.menu-burger__text');

            function activate() {
                $burgerMenu.addClass('active');
                $nav.addClass('active');
                $body.addClass('no-scroll');
            }

            function reset() {
                $burgerMenu.removeClass('active');
                $nav.removeClass('active').find('.active').removeClass('active');
                $body.removeClass('no-scroll');
            }

            $burgerMenu.click(function() {
                var $this = $(this);

                if ($this.hasClass('active')) { reset(); } else { activate(); }
            });
        }());

        var SearchMenu = (function() {
            var $searchOpen = $('.open-search');
            var $searchMenu = $('.search-menu');
            var $searchClose = $('.close-search');

            function openSearch() {
                $searchMenu.addClass('active').find('[type="text"]').focus();
                $body.addClass('no-scroll');
            }

            function closeSearch() {
                $searchMenu.removeClass('active');
                $body.removeClass('no-scroll');
            }

            $searchOpen.click(openSearch);
            $searchClose.click(closeSearch);

            return {
                open: openSearch,
                close: closeSearch
            }
        }());


        var DropdownMenus = (function() {
            var $menus = $('.menu');
            var $dropmenus = $menus.find('.dropdown.menu-item');
            var $mobileArrow = $dropmenus.find('.mobile-arrow');

            function toggleDropdown(e) {
                e.preventDefault();

                var $this = $(this);
                var $menuItem = $this.parent();


                if ($menuItem.hasClass('depth-0')) {
                    $dropmenus.not($menuItem).removeClass('active');
                }

                if ($menuItem.hasClass('active')) {
                    $menuItem.removeClass('active');
                } else {
                    $menuItem.addClass('active');
                }
            }

            $mobileArrow.click(toggleDropdown);

            $( ".item-lvl-1" ).hover(
              function() {
                var html = $(this).find('.depth-1').html();
                console.log(html);
                var menuContainer = $(this).parent().parent().find(".megamenu-menu");
                /*$(menuContainer).empty().html(html);*/
                $(menuContainer).html(html);
              }
            );


        }());

        var StickyHeader = (function () {
            if (!$header.hasClass('sticky')) { return; }

            if (window.scrollY) {
                $header.addClass('sticky--scrolled');
            }

            $(window).on('scroll', function () {

                if (window.scrollY) {
                    $header.addClass('sticky--scrolled');

                } else if (window.scrollY === 0) {
                    $header.removeClass('sticky--scrolled');
                }

                if ($adminBar.length) {
                    $header.css({ top: $adminBar.innerHeight() });
                }

            });
        }());
    }());

    var LoadMore = (function () {
        var $loadmore = $('#loadmore');
        if (!$loadmore.length) { return; }

        var $loadmore_text = $loadmore.text();
        var $totalCards = $( ".post-card" ).length;

           if($totalCards < 10) {
             $loadmore.remove();
           }

        $loadmore.click(function () {
            var $this = $(this);
            var $postlist = $(this).closest('section').find('.posts-row');
            var query = WP.query;
            var page = WP.current_page;
            var max = WP.max_page;
            var post_type = $postlist.attr('data-type');
            

            var data = {
                action: 'load_more_posts',
                query: query,
                page: page
            };
            if (post_type) { data['post_type'] = post_type; }

            $.ajax({
                url: ajaxURL,
                type: 'post',
                data: data,
                beforeSend: function () {
                    $loadmore.attr('disabled', true).text('Loading More');
                },
                error: function (res) {
                    res = JSON.parse(res);
                    console.log(res);
                },
                success: function (posts) {
                    if (posts) {
                        page = WP.current_page++;
                        $loadmore.attr('disabled', false).text($loadmore_text);
                        $postlist.append(posts);
                        LazyLoading.update();

                        if (WP.current_page >= max) { $loadmore.remove(); }
                    } else {
                        $loadmore.remove();
                    }
                }
            });
        });
    }());

 var productsSlider = (function () {
        $sliders = $('.products-slider');
        if (!$sliders.length) { return };
        $sliders.each(function () {
            var $slider = $(this);
            $slider.flickity({
                prevNextButtons: false,
                contain: true,
                cellAlign: 'left',
                pageDots: false,
                draggable: true,
                wrapAround: true
            });


                  // previous
            $slider.closest(".products-block").find('.flick-nav.prev').on('click', function () {
                $slider.flickity('previous');
            });
            // next
            $slider.closest(".products-block").find('.flick-nav.next').on('click', function () {
                $slider.flickity('next');
            });
        });
    }());

 var relatedPosts = (function () {
        $sliders = $('.related-posts__slider');
        if (!$sliders.length) { return };
        $sliders.each(function () {
            var $slider = $(this);
            $slider.flickity({
                prevNextButtons: false,
                contain: true,
                cellAlign: 'left',
                pageDots: false,
                draggable: false,
            });


                  // previous
            $slider.closest(".related-posts").find('.flick-nav.prev').on('click', function () {
                $slider.flickity('previous');
            });
            // next
            $slider.closest(".related-posts").find('.flick-nav.next').on('click', function () {
                $slider.flickity('next');
            });
        });
    }()); 

  var testimonialSlider = (function () {
        $sliders = $('.testimonials-block .testimonial-slider');
        if (!$sliders.length) { return };
        $sliders.each(function () {
            var $slider = $(this);
            var count = $(this).find(".testimonial-slider__item").length;

            if(count > 1) {
                $slider.flickity({
                    prevNextButtons: false,
                    contain: false,
                    pageDots: false,
                    draggable: true,
                    autoPlay: 4500
                });

                $(this).closest("section").find('.buttons-row').addClass("active");

                // Flickity instance
                var flkty = $slider.data('flickity');
                // elements
                var $cellButtonGroup = $('.button-dots');
                var $cellButtons = $cellButtonGroup.find('.button-dots__dot');

                // update selected cellButtons
                $slider.on('select.flickity', function () {
                    $cellButtons.filter('.is-selected')
                        .removeClass('is-selected');
                    $cellButtons.eq(flkty.selectedIndex)
                        .addClass('is-selected');
                });

                // select cell on button click
                $cellButtonGroup.on('click', '.button-dots__dot', function () {
                    var index = $(this).index();
                    $slider.flickity('select', index);
                });
            }

        });
    }());
gsap.registerPlugin(ScrollTrigger);
var desktopQuery = window.matchMedia('(min-width: 960px)');

  var heroParallax = (function() {
    $hero = $('.hero');
    if (!$hero.length) { 
        return };

    ScrollTrigger.saveStyles($hero);
    ScrollTrigger.matchMedia({
                    
        "(min-width: 960px)": function () {

            var heroTL = gsap.timeline({
                pause: false,
                defaults:{
                    duration: .5,
                    ease: Power2.easeIn,
                }
            });

             heroTL.set(".svg-icon--large-circle", {
                x: "100%",
            });

          /*    heroTL.set(".hero__content", {
                opacity: 0,
            });*/

             heroTL.set(".svg-icon--small-circle", {
                x: "-100%",
            });

            heroTL.to(".svg-icon--small-circle", {
                x: 0,
            },1);

        /*     heroTL.to(".hero__content", {
                opacity: 1,
            },1);*/

            heroTL.to(".svg-icon--large-circle", {
                x: 0,
            },1);            

            heroTL.play();

            gsap.to(".hero--basic .svg-icon--large-circle", {
                    x: function() { return ((1 - parseFloat($(".svg-icon--large-circle svg").data('speed'))) * ScrollTrigger.maxScroll(window) ) * -1  },
                    ease: "none",
                    scrollTrigger: {
                        trigger: ".hero--basic",
                        start: 'top top+=100px',
                        end: "bottom top",
                        invalidateOnRefresh: true,
                        scrub: 0
                    }
                });


             gsap.to(".hero--basic .svg-icon--small-circle", {
                    x: function() { var result = (1 - parseFloat($(".svg-icon--small-circle svg").data('speed'))) * ScrollTrigger.maxScroll(window); console.log(result); return result; },
                    ease: "none",
                    scrollTrigger: {
                        trigger: ".hero--basic",
                        start: 'top top+=100px',
                        end: "bottom top",
                        invalidateOnRefresh: true,
                        scrub: 0
                    }
                });

        }


    });


  }());


  var ctaBlock = (function() {
    $ctaBlock = $('.cta-block');
    if (!$ctaBlock.length) { 
        return };

    ScrollTrigger.saveStyles($ctaBlock);
    ScrollTrigger.matchMedia({
                    
        "(min-width: 960px)": function () {

            var ctaTL = gsap.timeline({
                pause: false,
                defaults:{
                    duration: .5,
                    ease: Power2.easeIn,
                }
            });

             ctaTL.set(".svg-icon--large-circle", {
                x: "100%",
            });


             ctaTL.set(".svg-icon--small-circle", {
                x: "-100%",
            });

            ctaTL.to(".svg-icon--small-circle", {
                x: 0,
            },1);


            ctaTL.to(".svg-icon--large-circle", {
                x: 0,
            },1);            

            ctaTL.play();

            gsap.to(".cta-block .svg-icon--large-circle", {
                    x: function() { return ((1 - parseFloat($(".svg-icon--large-circle svg").data('speed'))) * ScrollTrigger.maxScroll(window) ) * -1  },
                    ease: "none",
                    scrollTrigger: {
                        trigger: ".cta-block",
                        start: 'top top+=100px',
                        end: "bottom top",
                        invalidateOnRefresh: true,
                        scrub: 0
                    }
                });


             gsap.to(".cta-block .svg-icon--small-circle", {
                    x: function() { var result = (1 - parseFloat($(".svg-icon--small-circle svg").data('speed'))) * ScrollTrigger.maxScroll(window); console.log(result); return result; },
                    ease: "none",
                    scrollTrigger: {
                        trigger: ".cta-block",
                        start: 'top top+=100px',
                        end: "bottom top",
                        invalidateOnRefresh: true,
                        scrub: 0
                    }
                });

        }


    });


  }());

  var heroBanner = (function() {
    $banner = $('.hero-banner');
    if (!$banner.length) { 
        return };
            $banner.on('click', '.banner-close', function () {
            $banner.hide();
        });
  }());


  var navBarBlock = (function () {


            $sections = $('.same-scroll');
            var lastScrollTop = 0;

            function navBar() {
                if ($('.same-page-nav.navigation-bar').length) {
                    console.log("nav active");
                    var scrollTop = $(window).scrollTop();
                    $sections.each(function () {
                    var id = $(this).attr('id');

                        var elementOffset = $(this).offset().top,
                            distance = (elementOffset - scrollTop);

                        if (scrollTop > lastScrollTop) {
                            if (distance > 0 && distance < 250) {
                                var tocItem = $(this).attr('id');
                                $('.navigation-bar .btn.active').removeClass('active');
                                $('.navigation-bar .btn[data-target="#' + tocItem + '"]').addClass('active');
                            }
                        } else {
                            if (distance < (250) && distance < (500)) {
                                var tocItem = $(this).attr('id');
                                $('.navigation-bar .btn.active').removeClass('active');
                                $('.navigation-bar .btn[data-target="#' + tocItem + '"]').addClass('active');
                            }
                             if($(window).scrollTop() === 0) {
                                 $('.navigation-bar .btn.active').removeClass('active');
                             }
                        }
                    });

                    lastScrollTop = scrollTop;

                }                
            }
            $(window).scroll(navBar);
            $(window).resize(navBar);

        

    }());    


/* ========================================================================================================================

    Filter

======================================================================================================================== */


$('.category-list button').on('click', function() {
    $(this).closest("section").find(".loadmore-btn").addClass("hide");
    var categoryList = $(this).closest(".category-list");
    var data = $(this).data();
   $(categoryList).attr("data-value", JSON.stringify(data));
    var filters = [];
        $( ".category-list" ).each(function() {
            var value = $(this).attr("data-value");
            if(value.length) {
                filters.push(JSON.parse(value));
            }
        });
        var text = $(this).text();
        var catName = $(this).closest('.post-filter').find('.dropdown-btn__inner p');
        $(catName).html(text);
        $('.reset-button-container').removeClass('hide');

          $.ajax({
            type: 'POST',
            url: ajaxURL,
            dataType: 'html',
            data: {
              action: 'filter_posts',
              filter: JSON.stringify(filters),
            },
            success: function(res) {
              $('.posts-row').html(res);
                LazyLoading.update();
            }
          })

          console.log(filters);
    });


/*Blog Posts*/
/*$('.blog .category-list button').on('click', function() {

    $(this).closest("section").find(".loadmore-btn").addClass("hide");
    var categoryList = $(this).closest(".category-list");
    var data = $(this).data();

      $.ajax({
        type: 'POST',
        url: '/wp-admin/admin-ajax.php',
        dataType: 'html',
        data: {
          action: 'filter_blog_posts',
          category: $(this).data('slug'),
          taxonomy : $(this).data('taxonomy'),
          type: $(this).data('type'),
          term: $(this).data('term')
        },
        success: function(res) {
           $('.posts-row').html(res);
             LazyLoading.update();
        }
      })
    });
*/


});